import { Component, Inject, ViewChild } from '@angular/core';
//import { HttpClient, HttpParams } from '@angular/common/http';
import { MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';

import { City } from './city';
import { CityService } from './city.service';
import { ApiResult } from '../base.service';

@Component({
    selector: 'app-cities',
    templateUrl: './cities.component.html',
    styleUrls: ['./cities.component.css']
})
export class CitiesComponent {
    public displayedColumns: string[] = ['id', 'name', 'lat', 'lon', 'countryName'];
    //public cities: City[];
    public cities: MatTableDataSource<City>;

    defaultPageIndex: number = 0;
    defaultPageSize: number = 10;
    public defaultSortColumn: string = "name";
    public defaultSortOrder: string = "asc";

    defaultFilterColumn: string = "name";
    filterQuery: string = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    constructor(
        private cityService: CityService) {
    }

    ngOnInit() {
        this.loadData(null);

    }

    loadData(query: string = null) {
        var pageEvent = new PageEvent();
        pageEvent.pageIndex = this.defaultPageIndex;
        pageEvent.pageSize = this.defaultPageSize;
        if (query) {
            this.filterQuery = query;
        }
        this.getData(pageEvent);
    }

    getData(event: PageEvent) {

        var sortColumn = (this.sort)
            ? this.sort.active
            : this.defaultSortColumn;

        var sortOrder = (this.sort)
            ? this.sort.direction
            : this.defaultSortOrder;

        var filterColumn = (this.filterQuery)
            ? this.defaultFilterColumn
            : null;

        var filterQuery = (this.filterQuery)
            ? this.filterQuery
            : null;

        this.cityService.getData<ApiResult<City>>(
            event.pageIndex,
            event.pageSize,
            sortColumn,
            sortOrder,
            filterColumn,
            filterQuery)
            .subscribe(result => {
                this.paginator.length = result.totalCount;
                this.paginator.pageIndex = result.pageIndex;
                this.paginator.pageSize = result.pageSize;
                this.cities = new MatTableDataSource<City>(result.data);
            }, error => console.error(error));
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
    font-weight: bold;
}

.Healthy {
    color: green;
}

.Degraded {
    color: orange;
}

.Unhealthy {
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/health-check/health-check.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".status {\r\n    font-weight: bold;\r\n}\r\n\r\n.Healthy {\r\n    color: green;\r\n}\r\n\r\n.Degraded {\r\n    color: orange;\r\n}\r\n\r\n.Unhealthy {\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';


@NgModule({
    imports: [
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule
    ],
    exports: [
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule
    ]
})

export class AngularMaterialModule { }
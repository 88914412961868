// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-valid {
    border-left: 5px solid green;
}

input.ng-invalid.ng-dirty,
input.ng-invalid.ng-touched {
    border-left: 5px solid red;
}
 
input.ng-valid ~ .valid-feedback,
input.ng-invalid ~ .invalid-feedback {
    display: block;
}

`, "",{"version":3,"sources":["webpack://./src/app/countries/country-edit.component.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;;IAEI,0BAA0B;AAC9B;;AAEA;;IAEI,cAAc;AAClB","sourcesContent":["input.ng-valid {\r\n    border-left: 5px solid green;\r\n}\r\n\r\ninput.ng-invalid.ng-dirty,\r\ninput.ng-invalid.ng-touched {\r\n    border-left: 5px solid red;\r\n}\r\n \r\ninput.ng-valid ~ .valid-feedback,\r\ninput.ng-invalid ~ .invalid-feedback {\r\n    display: block;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

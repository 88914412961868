import { Component, Inject } from '@angular/core';
//import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { City } from './City';
import { Country } from './../countries/country';

import { CityService } from './city.service';
import { ApiResult } from '../base.service';

import { BaseFormComponent } from '../base.form.component';

@Component({
    selector: 'app-city-edit',
    templateUrl: './city-edit.component.html',
    styleUrls: ['./city-edit.component.css']
})
export class CityEditComponent
    extends BaseFormComponent {

    // the view title
    title: string;

    // the form model
    form: UntypedFormGroup;

    // the city object to edit or create
    city: City;

    // the city object id, as fetched from the active route:
    // It's NULL when we're adding a new city,
    // and not NULL when we're editing an existing one.
    id?: number;

    // the countries array for the select
    countries: Country[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        //private http: HttpClient,
        //@Inject('BASE_URL') private baseUrl: string
        private cityService: CityService
    ) {
        super();
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            lat: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(/^[-]?[0-9]+(\.[0-9]{1,4})?$/)
            ]),
            lon: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(/^[-]?[0-9]+(\.[0-9]{1,4})?$/)
            ]),
            countryId: new UntypedFormControl('', Validators.required)
        }, null, this.isDupeCity());

        this.loadData();
    }

    //async validator
    isDupeCity(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            var city = <City>{};
            city.id = (this.id) ? this.id : 0;
            city.name = this.form.get("name").value;
            city.lat = this.form.get("lat").value;
            city.lon = this.form.get("lon").value;
            city.countryId = +this.form.get("countryId").value;

            return this.cityService.isDupeCity(city)
                .pipe(map(result => {
                    return (result ? { isDupeCity: true } : null);
                }));
        }
    }

    loadData() {
        this.loadCountries();
        this.id = +this.activatedRoute.snapshot.paramMap.get('id');

        console.log('Get city....' + this.id);

        if (this.id) {
            //Edit mode
            this.cityService.get<City>(this.id)
                .subscribe(result => {
                    this.city = result;
                    this.title = "Edit - " + this.city.name;
                    this.form.patchValue(this.city);
                }, error => console.error(error));
        }
        else {
            // ADD mode
            this.title = "Create and add a new City"
        }

        console.log('Get city.... done');
    }

    loadCountries() {
        this.cityService.getCountries<ApiResult<Country>>(0, 9999, "name", null, null, null)
            .subscribe(result => {
                this.countries = result.data;
            }, error => console.error(error));
    }

    onSubmit() {
        var city = (this.id) ? this.city : <City>{};

        city.name = this.form.get("name").value;
        city.lat = this.form.get("lat").value;
        city.lon = this.form.get("lon").value;
        city.countryId = +this.form.get("countryId").value;

        if (this.id) {
            //Edit mode            
            this.cityService
                .put<City>(city)
                .subscribe(result => {
                    console.log("City " + city.id + "has been updated.");

                    this.router.navigate(['/cities']);
                }, error => console.log(error));
        }
        else {
            //ADD mode            
            this.cityService
                .post<City>(city)
                .subscribe(result => {
                    console.log("City " + result.id + " has been added");

                    //go back to cities view
                    this.router.navigate(['/cities']);
                }, error => console.log(error));
        }
    }
}